@import '../../styles/settings/spaces.module';

.image-parser {
  width: auto;
  height: auto;

  &--sm {
    width: 50px;
  }

  &--logo {
    max-height: 50px;
    margin: 0 $space-1;
  }

  &--md {
    width: 100px;
  }

  &--lg {
    width: 150px;
  }

  &--full {
    width: 100%;
  }
}
