@import '../../styles/settings/colors.module';
@import '../../styles/settings/spaces.module';
@import '../../styles/settings/radius.module';
@import '../../styles/settings/fonts.module';

.tag {
  padding: $space-0 $space-0 $space-0 $space-2;
  border-radius: $radius-1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: $color-white;
  background-color: $color-primary-600;
  font-size: $font-size-sm;
  font-weight: 400;
  margin-bottom: $space-1;
  margin-right: $space-1;

  button {
    margin-left: $space-2;
    border: none;
    background-color: transparent;
    border-radius: $radius-1;
    padding: 0;
    height: $space-2;
    width: $space-2;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: $font-size-sm;
  }
}

.simple-tag {
  padding: $space-1;
  font-size: $font-size-xs;
  text-align: center;
  display: inline-block;
  border-radius: $radius-1;
  font-weight: 400;
  width: 100%;
  margin-right: $space-1;

  @media (max-width: 463px) {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;
  }

  &--grayLight {
    color: $color-neutral-500;
    background-color: $color-neutral-100;
  }

  &--primary {
    color: $color-primary-600;
    background-color: $color-primary-200;
  }

  &--success {
    color: $color-teal-500;
    background-color: $color-teal-100;
  }

  &--danger {
    color: $color-orange-700;
    background-color: $color-orange-600;
  }

  &--warning {
    color: $color-orange-500;
    background-color: $color-orange-300;
  }
}
