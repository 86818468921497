@import "../../styles/settings/colors.module";
@import "../../styles/settings/radius.module";

:global {
  .tox-tinymce {
    border: solid 1px $color-neutral-400 !important;
    border-radius: $radius-1 !important;
  }

  .tox .tox-tbtn svg {
    fill: $color-neutral-600 !important;
  }

  .tox .tox-editor-header {
    z-index: unset;
  }

  .tox .tox-toolbar__primary {
    background: unset !important;
    border-bottom: solid 1px $color-neutral-400 !important;
  }
}
