$radius: 4px;

$radius-1: $radius;
$radius-2: $radius * 2;
$radius-3: $radius * 3;
$radius-4: $radius * 4;
$radius-5: $radius * 5;

$radius-percent-50: 50%;

:export {
    radius: $radius;
    radius1: $radius-1;
    radius2: $radius-2;
    radius3: $radius-3;
    radius4: $radius-4;
    radius5: $radius-5;
    radiusPercent50: $radius-percent-50;
}