$font-family: 'inter', sans-serif;
$font-family-secondary: 'inter', sans-serif;
$font-family-awesome-5-pro: 'Font Awesome 5 Pro', sans-serif;
$font-size-xg: 28px;
$font-size-lg: 16px;
$font-size-md: 14px;
$font-size-sm: 12px;
$font-size-xs: 10px;
$font-size-h1: 48px;
$font-size-h2: 40px;
$font-size-h3: 32px;
$font-size-h4: 24px;
$font-size-h5: 20px;
$weight1: 'normal';
$weight300: 300;
$weight400: 400;
$weight500: 500;
$weight600: 600;
$weight700: 700;

:export {
    family: $font-family;
    familyAwesomePro: $font-family-awesome-5-pro;
    sizeXg: $font-size-xg;
    sizeLg: $font-size-lg;
    sizeMd: $font-size-md;
    sizeSm: $font-size-sm;
    sizeXs: $font-size-xs;
    sizeH1: $font-size-h1;
    sizeH2: $font-size-h2;
    sizeH3: $font-size-h3;
    sizeH4: $font-size-h4;
    sizeH5: $font-size-h5;
    weight1: $weight1;
    weight300: $weight300;
    weight400: $weight400;
    weight500: $weight500;
    weight600: $weight600;
    weight700: $weight700;
}
