@import "../../styles/settings/spaces.module";
@import "../../styles/settings/colors.module";
@import "../../styles/settings/radius.module";
@import "../../styles/settings/fonts.module";
@import "../../styles/tools/mq";

.c-no-content {
  display: flex;
  align-items: center;
  padding: $space-6 0 !important;
  background-color: $color-white;
  border-radius: $radius-1;
  justify-content: center;
  width: 100%;

  @include mq($until: tablet) {
    flex-direction: column;
    padding: $space-2 0;
    text-align: center;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    align-items: center;
    max-width: 70%;
    text-align: center;
    @include mq($until: tablet) {
      width: 100%;
      max-width: 100% !important;
    }
  }

  &__info {
    flex-direction: column;

    @include mq($until: tablet) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__icon {
    color: $color-primary-600;
  }

  &__content {
    @include mq($until: tablet) {
      margin-top: $space-2;
      padding: 0 $space-2;
    }
    display: flex;
    flex-direction: column;

    button {
      margin-top: $space-2;
    }
  }

  &__extra-info {
    margin-top: $space-7;

    @include mq($until: tablet) {
      margin-top: $space-4;
    }
  }
}
