@import "../../styles/settings/radius.module";
@import "../../styles/settings/shadows.module";

.c-preview-files {
  margin-top: 150px;

  &__img {
    max-width: 100%;
    object-fit: cover;
    box-shadow: $elevation-1;
    border-radius: $radius-1;
    max-height: 600px;
  }
  &__pdf{
    height: 100%;
    width: 100%;
    box-shadow: $elevation-1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
