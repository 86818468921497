@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-Regular.ttf');
}

@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-UltraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'gilroy';
  src: url('../../../public/assets/fonts/gilroy/Gilroy-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'muli';
  src: url('../../../public/assets/fonts/muli/Muli-Regular.ttf');
}

@font-face {
  font-family: 'muli';
  src: url('../../../public/assets/fonts/muli/Muli-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'muli';
  src: url('../../../public/assets/fonts/muli/Muli-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'muli';
  src: url('../../../public/assets/fonts/muli/Muli-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'muli';
  src: url('../../../public/assets/fonts/muli/Muli-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'muli';
  src: url('../../../public/assets/fonts/muli/Muli-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'muli';
  src: url('../../../public/assets/fonts/muli/Muli-Black.ttf');
  font-weight: 900;
}


@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../../../public/assets/fonts/Inter/static/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}
