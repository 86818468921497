@import "../../styles/settings/colors.module";
@import "../../styles/settings/spaces.module";
@import "../../styles/settings/radius.module";
@import "../../styles/tools/mq";

.c-gallery {
  display: flex;

  &--small {
    text-align: center;
  }

  &--unique {
    display: block;
    height: 120px;
    max-width: 120px;
  }
}
