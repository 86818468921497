@use './colors.module';

$shadow: 8px;

$elevation-0: 0 calc($shadow / 2) $shadow 0 rgba(colors.$color-black, 0.08);
$elevation-1: 0 $shadow ($shadow * 2) 0 rgba(colors.$color-black, 0.08);
$elevation-2: 0 ($shadow * 2) ($shadow * 3) 0 rgba(colors.$color-black, 0.08);
$elevation-3: 0 ($shadow * 3) ($shadow * 4) 0 rgba(colors.$color-black, 0.08);
$elevation-4: 0 ($shadow * 4) ($shadow * 5) 0 rgba(colors.$color-black, 0.08);

$box-shadow: '2px 2px 4px 0 rgba(0, 0, 0, .05)';

:export {
    elevation0: $elevation-0;
    elevation1: $elevation-1;
    elevation2: $elevation-2;
    elevation3: $elevation-3;
    elevation4: $elevation-4;
    boxShadow: $box-shadow;
}