$space: 8px;

$space-0: calc($space / 2);
$space-1: $space;
$space-2: $space * 2;
$space-3: $space * 3;
$space-4: $space * 4;
$space-5: $space * 5;
$space-6: $space * 6;
$space-7: $space * 7;
$space-8: $space * 8;
$space-9: $space * 9;
$space-10: $space * 10;
$space-11: $space * 11;
$space-12: $space * 12;
$space-13: $space * 13;
$space-14: $space * 14;
$space-15: $space * 15;
$space-16: $space * 16;

:export {
    space0: $space-0;
    space1: $space-1;
    space2: $space-2;
    space3: $space-3;
    space4: $space-4;
    space5: $space-5;
    space6: $space-6;
    space7: $space-7;
    space8: $space-8;
    space9: $space-9;
    space10: $space-10;
    space11: $space-11;
    space12: $space-12;
    space13: $space-13;
    space14: $space-14;
    space15: $space-15;
    space16: $space-16;
}
