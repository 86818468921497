// Default cubic-bezier config
$cubic-bezier: cubic-bezier(.65, -0.79, .29, 1.76);

// header
$header-height: 48px;

// Default transition time
$transition-time: .3s;

// Navigation
$nav-width: 64px;
$nav-width--collapsed: 250px;

// Profile
$profile-height: 35px;
$profile-width: 35px;

// Login
$login-btn-height: 38px;

// Fixed actions
$fixed-actions-height: $header-height;

// file details footer
$file-details-footer-height: 44px;
