@import "../../styles/settings/colors.module";
@import "../../styles/settings/spaces.module";
@import "../../styles/settings/fonts.module";
@import "../../styles/tools/mq";
@import "../../styles/settings/radius.module";
@import "../../styles/variables";

.c-file-comments {
  overflow: auto;
  height: calc(100% - 75px);

  &::-webkit-scrollbar {
      display: none;
    }

  &:hover {
    &::-webkit-scrollbar {
      display: block !important;
      width: 4px;
    }
  }
}

.c-no-comments {
  display:  flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $color-neutral-400;
  margin-top: $space-2;

  p {
    margin-top: $space-1;
    width: 65%;
    text-align: center;
  }

  svg {
    font-size: $font-size-md * 2;
  }
}

.action{
  display: flex;
  justify-content: space-between;
  padding: $space-2;
  width: 100%;
}
