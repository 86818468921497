@use "../../styles/settings/colors.module";
@use "../../styles/settings/fonts.module";
@use "../../styles/settings/spaces.module";
@import "../../styles/tools/mq";

.c-attachment-grid {
  @include mq($until: tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.c-attachment-flex {
  display: flex;
}

.c-attachment {
  &--header {
    margin-top: spaces.$space-2;
    margin-bottom: spaces.$space-2;
  }

  &--list {
    font-size: fonts.$font-size-sm;
  }

  &--action {
    align-self: center;
    padding-left: spaces.$space-1;

    svg {
      color: colors.$color-red-500;
    }
  }

  &--vertical-center {
    align-self: center;
  }

  :global {
    .FileInput-module_c-file__empty__1GLK2 {
      font-size: fonts.$font-size-sm;
      padding: spaces.$space-1;

      svg {
        width: 0.8em;
      }
    }

    .FileCard_c-file-card__icon__2x7Qn {
      height: 24px;
    }
    .FileCard_c-file-card__icon__qezQv img {
      height: 100%;
    }
  }
}
