@import '../../styles/settings/spaces.module';

.c-filter-default {
  width: 235px;
  overflow: hidden auto;
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &__checkbox + &__checkbox {
    margin-top: $space-1;
  }

  &__checkbox {
    width: 100%;
    display: flex;
    align-items: center;

    label {
      margin: 0 0 0 $space-1;
    }
  }
}
