@use "../../styles/settings/colors.module" as *;
@use "../../styles/settings/radius.module";
@use "../../styles/settings/spaces.module";
@import "../../styles/tools/mq";

.table-heading {
    display: flex;
    align-items: center;
    min-height: 48px;
    width: 100%;
    background-color: #f1f3f9;
    border-radius: radius.$radius-1 radius.$radius-1 0 0;
    justify-content: space-between;
    cursor: pointer;

    @include mq($until: tablet) {
      background-color: #ffffff;
      border-radius: radius.$radius-1;
      margin-top: spaces.$space-1;
    }

  .table-heading-actions {
    margin: 1em;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-around;
    gap: spaces.$space-2;
  }
}


