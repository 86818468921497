@use './settings/opacities.module';
@use './settings/spaces.module';
@use './settings/colors.module';
@use './settings/radius.module';
@use './settings/fonts.module';
@use './settings/settings.module';
@use './tools/mq';
@use './tools/tools';
@use './generic/generic';

html {
  -webkit-font-smoothing: unset !important;
  text-rendering: auto !important;
}

html,
body,
#root {
  @extend %paragraph;
  color: colors.$color-neutral-600;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: fonts.$font-family !important;
}

p {
  line-height: 1.5;
  font-size: fonts.$font-size-md;
}

span {
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: fonts.$font-family;
  color: colors.$color-neutral-700;
  line-height: 1.3;
  font-weight: fonts.$weight600;
}

h1 {
  font-size: fonts.$font-size-h1;
}

h2 {
  font-size: fonts.$font-size-h2;
}

h3 {
  font-size: fonts.$font-size-h3;
}

h4 {
  font-size: fonts.$font-size-h4;
}

h5 {
  font-size: fonts.$font-size-h5;
}

h6 {
  font-size: 20px;
  font-weight: 500;
}

input {
  @extend %paragraph;
  color: colors.$color-neutral-600;

  &::placeholder {
    color: colors.$color-neutral-400;
  }

  transition: border-color 0.3s ease-in-out;
}

a {
  @extend %medium;
  color: colors.$color-primary-600;
  font-weight: normal;
  line-height: 1.5;

  &:hover,
  &:link {
    color: colors.$color-primary-600;
    text-decoration: none;
  }
}

label {
  @extend %small;
  color: colors.$color-neutral-500;
  margin-bottom: spaces.$space-0;
  font-weight: fonts.$weight500;
  line-height: 1.5;
}

small {
  @extend %extra-small;
  color: colors.$color-neutral-600;
  font-weight: fonts.$weight400;
  line-height: 1.4;
}

#root {
  width: 100vw;

  @include mq.mq($until: tablet) {
    overflow: hidden;
  }
}

.pointer {
  cursor: pointer;
}

.centered {
  margin: auto;
  width: fit-content;
  padding-top: spaces.$space-5;
}

.newBtn {
  text-align: right;
  min-width: fit-content;
  .Button-module_c-btn__1xMYr {
    padding: spaces.$space-1 12px;
  }
}

.hideMobile {
  @include mq.mq($until: desktop) {
    display: none !important;
  }
}

.showMobile {
  display: none;
  @include mq.mq($until: tablet) {
    display: block !important;
  }
}

.showFlexMobile {
  display: none;
  @include mq.mq($until: tablet) {
    display: flex !important;
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.ant-dropdown-menu-submenu-popup ul {
  margin-right: spaces.$space-1;
  margin-left: spaces.$space-1;
}

.ant-dropdown-menu {
  padding: 0;
}

.vobi-submenu-popup {
  .fa-lock {
    position: absolute;
    right: 0;
    top: 32%;
    padding: 0 8px;
  }
  .ant-menu-item-selected {
    background-color: colors.$color-white !important;
    ::after {
      opacity: 0;
    }
  }
  .ant-menu-item {
    span {
      font-size: fonts.$font-size-sm;
      color: colors.$color-primary-800;
    }
    margin: spaces.$space-0 !important;
    &:hover {
      background-color: #{colors.$color-primary-300} + #{opacities.$opacity-02} !important;
      border-radius: radius.$radius-1;
    }
  }
  .submenu-item-selected {
    background-color: #{colors.$color-primary-300} + #{opacities.$opacity-02} !important;
    border-radius: radius.$radius-1;
  }
}

::-webkit-scrollbar {
  width: spaces.$space-1;
  height: spaces.$space-1;
}
::-webkit-scrollbar-track {
  background: colors.$color-neutral-75;
}
::-webkit-scrollbar-thumb {
  background: colors.$color-neutral-200;
  border-radius: radius.$radius-1;
}

.gantt_tooltip {
  border-radius: radius.$radius-1;
  padding: 0 !important;
  border: 0 !important;
  box-shadow: 0px 4px 12px 0px hsla(0, 0%, 0%, 0.1) !important;
}

.gantt_link_tooltip {
  border-radius: radius.$radius-1;
  padding: 0 !important;
  border: 0 !important;
  box-shadow: 0px 4px 12px 0px hsla(0, 0%, 0%, 0.1) !important;
}

.gantt_drag_marker {
  border-radius: radius.$radius-1;
}

@media print {
  body {
    display: block !important;

    position: relative !important;

    width: auto !important;

    height: auto !important;

    overflow: visible !important;

    margin-left: 0 !important;

    .hide-on-print {
      display: none !important;
    }

    .main-router-container {
      background-color: colors.$color-white !important;
    }

    .customScale {
      zoom: 0.8;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

.gantt-error {
  display: none !important;
}

@media (max-width: 768px) {
  .ant-select-dropdown {
    z-index: 1000;
  }
}
