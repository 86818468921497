@import '../styles/variables';
@import '../styles/settings/radius.module';
@import '../styles/settings/fonts.module';
@import '../styles/settings/colors.module';
@import '../styles/settings/spaces.module';
@import '../styles/tools/mq';

.c-main {
  min-height: calc(100% - 78px);
  transition: padding-left 0.4s $cubic-bezier;
  position: relative;
  height: 100%;
  overflow: auto;
  @include mq($until: tablet) {
    position: initial;
    min-height: unset;
    overflow: hidden;
  }

  &--collapse {
    padding-left: $nav-width--collapsed;

    @include mq($until: tablet) {
      padding-left: 0;
    }
  }

  @include mq($until: tablet) {
    margin-left: 0;
  }
}

.c-mail-router {
  min-height: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @include mq($until: tablet) {
    position: relative;
    background-color: $color-neutral-75;
  }
}

.c-main--background-white {
  background-color: $color-white;
}
