@import "../../styles/settings/colors.module";
@import "../../styles/settings/spaces.module";
@import "../../styles/settings/shadows.module";
@import "../../styles/settings/radius.module";
@import "../../styles/tools/mq";

.c-box {
  background-color: $color-white;
  padding: $space-3;
  border-radius: $radius-1;
  border: solid 1px $color-white;
  position: relative;

  &+.c-box {
    margin-top: $space-2;
  }

  @include mq($until: tablet) {
    padding: $space-2;
  }

  @include mq($until: tablet) {
    margin-top: $space-1;
  }

  &--elevation-1 {
    box-shadow: $elevation-0;
  }

  &--elevation-2 {
    box-shadow: $elevation-1;
  }

  &--elevation-3 {
    box-shadow: $elevation-2;
  }

  &--elevation-4 {
    box-shadow: $elevation-3;
  }

  &--elevation-5 {
    box-shadow: $elevation-4;
  }

  &--margin-bottom-1 {
    margin-bottom: $space-1;
  }

  &--margin-bottom-2 {
    margin-bottom: $space-2;
  }

  &--margin-bottom-3 {
    margin-bottom: $space-3;
  }

  &--margin-bottom-4 {
    margin-bottom: $space-4;
  }
}

:global {
  .bordered {
    border: 1px solid $color-neutral-100;
    padding: $space-2;
  }
}

.c-page-break {
  tr,
  td,
  th {
    page-break-inside: avoid !important;
  }
}
