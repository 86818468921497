@import '../../styles/settings/spaces.module';
@import '../../styles/settings/radius.module';
@import '../../styles/settings/fonts.module';
@import '../../styles/settings/colors.module';
@import '../../styles/tools/mq';

.c-file-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: $space-3;
  height: 160px;
  width: 168px;
  cursor: pointer;
  border: 1px solid $color-neutral-100;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  position: relative;

  @include mq($until: tablet) {
    min-height: 124px;
  }

  &:hover {
    border: 1px solid $color-primary-400;

    button {
      display: flex;
    }
  }

  &--selected {
    border: 1px solid $color-primary-400;
  }

  &__icon {
    background-color: $color-primary-50;
    border-radius: $space-0;
    color: $color-primary-600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;

    button {
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 4px;
      right: 4px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: $color-primary-600;
      color: #fff;
      border: 0;

      display: none;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        filter: brightness(0.8);
      }
    }

    img {
      width: 100%;
      height: 85px;
      object-fit: cover;
      border-radius: $radius-1;
    }
  }

  &__text {
    width: 100%;
    padding-top: 8px;
    text-align: left;
    display: flex;
    flex-direction: column;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: initial;
    }
  }
}
