@use "../settings/settings.module";
@use "../settings/fonts.module";


%large, %paragraph, %medium, %small {
  font-family: fonts.$font-family;
}

%large {
  font-size: fonts.$font-size-lg
}

%paragraph {
  font-size: fonts.$font-size-md
}

%small {
  font-size: fonts.$font-size-sm;
}

%extra-small {
  font-size: fonts.$font-size-xs;
}
