@import '../../styles/settings/spaces.module';
@import '../../styles/settings/colors.module';
@import '../../styles/settings/fonts.module';
@import '../../styles/settings/radius.module';

.custom {
  --dhx-color-primary: #{$color-primary-600};
  --dhx-font-color-secondary: #{$color-neutral-400};
  --dhx-color-secondary: #{$color-neutral-800};
  --dhx-font-family: #{$font-family};
  --dhx-font-size-normal: #{$font-size-sm};
  --dhx-border-shadow-normal: 0px 12px 12px 0px #0000001a;

  .dhx_calendar-day:before {
    border-radius: #{$radius-1};
  }

  .dhx_button--view_link {
    font-size: #{$font-size-sm};
  }

  .dhx_calendar-day--muffled {
    color: #{$color-neutral-400};
    opacity: 1 !important;
  }

  .dhx_calendar {
    border-radius: #{$radius-1};
  }
}
