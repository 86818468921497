$color-primary-900: #001A43;
$color-primary-800: #002E6C;
$color-primary-700: #004396;
$color-primary-600: #005AC3;
$color-primary-500: #2F74DE;
$color-primary-400: #4F8EFA;
$color-primary-300: #7EABFF;
$color-primary-200: #ACC7FF;
$color-primary-100: #D6E2FF;
$color-primary-50: #EBF0FF;

$color-teal-900: #00443f;
$color-teal-800: #005953;
$color-teal-700: #00736b;
$color-teal-600: #009389;
$color-teal-500: #00a296;
$color-teal-400: #33b5ab;
$color-teal-300: #54c1b9;
$color-teal-200: #8ad4cf;
$color-teal-100: #b0e2de;
$color-teal-50: #e6f6f5;

$color-green-900: #1d4413;
$color-green-800: #275919;
$color-green-700: #327321;
$color-green-600: #40932a;
$color-green-500: #46a22e;
$color-green-400: #6bb558;
$color-green-300: #83c173;
$color-green-200: #aad49f;
$color-green-100: #c6e2be;
$color-green-50: #edf6ea;

$color-red-900: #641b16;
$color-red-800: #83241d;
$color-red-700: #aa2e25;
$color-red-600: #d93b2f;
$color-red-500: #ef4134;
$color-red-400: #f2675d;
$color-red-300: #f48077;
$color-red-200: #f8a8a2;
$color-red-100: #fac4c0;
$color-red-50: #fdeceb;

$color-yellow-900: #4e3a08;
$color-yellow-800: #664c0a;
$color-yellow-700: #84620d;
$color-yellow-600: #a97e10;
$color-yellow-500: #ba8a12;
$color-yellow-400: #e0a819;
$color-yellow-300: #eac565;
$color-yellow-200: #f1d795;
$color-yellow-100: #f5e4b8;
$color-yellow-50: #fcf6e8;

$color-orange-900: #5d3100;
$color-orange-800: #7a4000;
$color-orange-700: #9e5200;
$color-orange-600: #ca6a00;
$color-orange-500: #de7400;
$color-orange-400: #f78007;
$color-orange-300: #faaa59;
$color-orange-200: #fbc58d;
$color-orange-100: #fdd8b2;
$color-orange-50: #fef2e6;

$color-neutral-900: #131B2C;
$color-neutral-800: #283041;
$color-neutral-700: #3F4759;
$color-neutral-600: #565E71;
$color-neutral-500: #6F778B;
$color-neutral-400: #8A94AD;
$color-neutral-300: #A6AFC7;
$color-neutral-200: #CFD5E7;
$color-neutral-100: #E3E8F6;
$color-neutral-75: #F5F7FD;
$color-neutral-50: #FBFBFD;

$color-pink-900: #601e46;
$color-pink-800: #7e275b;
$color-pink-700: #a33276;
$color-pink-600: #d04197;
$color-pink-500: #e547a6;
$color-pink-400: #ea6cb8;
$color-pink-300: #ee84c3;
$color-pink-200: #f3aad6;
$color-pink-100: #f7c6e3;
$color-pink-50: #fcedf6;

$color-violet-900: #441d5d;
$color-violet-800: #59267a;
$color-violet-700: #72319e;
$color-violet-600: #933fca;
$color-violet-500: #a145de;
$color-violet-400: #b46ae5;
$color-violet-300: #c082e9;
$color-violet-200: #d4a9f0;
$color-violet-100: #e2c5f5;
$color-violet-50: #f6ecfc;

$color-white: #ffffff;
$color-black: #000000;

:export {
    primary50: $color-primary-50;
    primary100: $color-primary-100;
    primary200: $color-primary-200;
    primary300: $color-primary-300;
    primary400: $color-primary-400;
    primary500: $color-primary-500;
    primary600: $color-primary-600;
    primary700: $color-primary-700;
    primary800: $color-primary-800;
    primary900: $color-primary-900;

    teal50: $color-teal-50;
    teal100: $color-teal-100;
    teal200: $color-teal-200;
    teal300: $color-teal-300;
    teal400: $color-teal-400;
    teal500: $color-teal-500;
    teal600: $color-teal-600;
    teal700: $color-teal-700;
    teal800: $color-teal-800;
    teal900: $color-teal-900;

    neutral50: $color-neutral-50;
    neutral75: $color-neutral-75;
    neutral100: $color-neutral-100;
    neutral200: $color-neutral-200;
    neutral300: $color-neutral-300;
    neutral400: $color-neutral-400;
    neutral500: $color-neutral-500;
    neutral600: $color-neutral-600;
    neutral700: $color-neutral-700;
    neutral800: $color-neutral-800;
    neutral900: $color-neutral-900;

    black: $color-black;
    white: $color-white;

    red50: $color-red-50;
    red100: $color-red-100;
    red200: $color-red-200;
    red300: $color-red-300;
    red400: $color-red-400;
    red500: $color-red-500;
    red600: $color-red-600;
    red700: $color-red-700;
    red800: $color-red-800;
    red900: $color-red-900;

    green50: $color-green-50;
    green100: $color-green-100;
    green200: $color-green-200;
    green300: $color-green-300;
    green400: $color-green-400;
    green500: $color-green-500;
    green600: $color-green-600;
    green700: $color-green-700;
    green800: $color-green-800;
    green900: $color-green-900;

    yellow50: $color-yellow-50;
    yellow100: $color-yellow-100;
    yellow200: $color-yellow-200;
    yellow300: $color-yellow-300;
    yellow400: $color-yellow-400;
    yellow500: $color-yellow-500;
    yellow600: $color-yellow-600;
    yellow700: $color-yellow-700;
    yellow800: $color-yellow-800;
    yellow900: $color-yellow-900;

    orange50: $color-orange-50;
    orange100: $color-orange-100;
    orange200: $color-orange-200;
    orange300: $color-orange-300;
    orange400: $color-orange-400;
    orange500: $color-orange-500;
    orange600: $color-orange-600;
    orange700: $color-orange-700;
    orange800: $color-orange-800;
    orange900: $color-orange-900;

    pink50: $color-pink-50;
    pink100: $color-pink-100;
    pink200: $color-pink-200;
    pink300: $color-pink-300;
    pink400: $color-pink-400;
    pink500: $color-pink-500;
    pink600: $color-pink-600;
    pink700: $color-pink-700;
    pink800: $color-pink-800;
    pink900: $color-pink-900;

    violet50: $color-violet-50;
    violet100: $color-violet-100;
    violet200: $color-violet-200;
    violet300: $color-violet-300;
    violet400: $color-violet-400;
    violet500: $color-violet-500;
    violet600: $color-violet-600;
    violet700: $color-violet-700;
    violet800: $color-violet-800;
    violet900: $color-violet-900;
}
