@import "../../styles/settings/spaces.module";
@import "../../styles/settings/colors.module";
@import "../../styles/settings/fonts.module";
@import "../../styles/settings/radius.module";

.time-picker-popup {
          .ant-picker-time-panel-column {
            &::-webkit-scrollbar {
              display: none;
            }
            &:hover {
              &::-webkit-scrollbar {
                display: block;
                width: 4px;
              }
            }
          }
        }
